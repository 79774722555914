<template>
  <img :src="src" alt="位元灵感" class="goods-info-pic" @error="setErrImg" v-bind="attrs"/>
</template>

<script setup>
import {ref, toRefs, useAttrs} from "vue";

const defaultImg = 'https://oss.musaemotion.com/WEB/assets/img/index/productDefaultImg.png'
const props = defineProps({
  src: {
    type: String,
    default: 'https://oss.musaemotion.com/WEB/assets/img/index/productDefaultImg.png'
  },
  size: {
    type: String,
    default: "72"
  }
})
const setErrImg = (e) => {
  e.target.src = defaultImg
}
const attrs = useAttrs()
const {src, size} = toRefs(props)
const imgSize = ref(size.value + 'px')
</script>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'GoodImg',
  inheritAttrs: false,
})
</script>

<style lang="scss" scoped>
.goods-info-pic {
  width: v-bind(imgSize);
  height: v-bind(imgSize);
  object-fit: cover;
}
</style>